import axios from "axios";

let authApi = axios.create({
  baseURL: "https://rzehziqrmb.execute-api.us-west-2.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});

let authApi2 = axios.create({
  baseURL: "https://ld3igodwbj.execute-api.us-west-2.amazonaws.com/prod/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default {

  sendOtp(value){
    return authApi.post("agent_delete_account", value);
  },

  fetchAgent(value){
    return authApi2.post("cableguy2-mobile-user-login-new", value);
  }

  

}



