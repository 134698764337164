




// export default LoginPage;
import React, { useState } from 'react';
import './LoginPage.css'; // Import your CSS file for styling
import authApi from './authApi';
import Loader from './loader/loader'; // Correct import path

// Define YourComponent outside of LoginPage if it's a separate component
const YourComponent = ({ handleCheckboxChange, selectedOptions, otpvalue, setOtpvalue, handleSubmit }) => {
  return (
    <div className="container">
      <div className="bottom-right" style={{ bottom: '30%' }}>
        <form onSubmit={handleSubmit}>
          {/* Checkboxes */}
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                id="option1"
                checked={selectedOptions.option1}
                onChange={handleCheckboxChange}
              />
              Switched to a different product
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                id="option2"
                checked={selectedOptions.option2}
                onChange={handleCheckboxChange}
              />
             Accidentally created an account
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                id="option3"
                checked={selectedOptions.option3}
                onChange={handleCheckboxChange}
              />
              Sold my network and no longer require this account.
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                id="option4"
                checked={selectedOptions.option4}
                onChange={handleCheckboxChange}
              />
              Not satisfied with the product
            </label>
          </div>
          {/* Textbox */}
          <div className="form-group">
            <input
              type="text"
              id="textBox"
              placeholder="Enter OTP"
              value={otpvalue}
              onChange={(e) => setOtpvalue(e.target.value)}
              required
            />
          </div>
          {/* Submit Button */}
          <button type="submit" className="submit-button-second">Enter OTP</button>
        </form>
      </div>
    </div>
  );
};

const LoginPage = () => {
  const [loading, setLoading] = useState(false); // Initially set to false
  const [verified, setVerified] = useState(false);
  const [vcNumber, setVcNumber] = useState('');
  const [password, setPassword] = useState('');
  const [otpvalue, setOtpvalue] = useState('');
  const [otpCompare, setOtpCompare] = useState('');
  const [agentID, setAgentID] = useState('');
  const [selectedOptions, setSelectedOptions] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false
  });

  // Handler for checkbox changes
  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [id]: checked
    }));
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const target = event.nativeEvent.submitter;

    if (target.classList.contains('submit-button-second')) {
      console.log('Second submit button clicked');
      console.log(otpCompare);
      console.log(otpvalue);
      if (otpCompare == otpvalue) {
        console.log('OTP Matched');
        const data = {
           agent_id:agentID,
           flag:"B"
          };
    
          try {
            const res = await authApi.sendOtp(data);
            if (res.status === 200) {
              console.log(res.data);
              console.log(res.data);
                alert("Account Deleted")
                window.location.reload();
            }
          } catch (error) {
            // console.log('Failed to fetch agent', error);
            alert('An error occurred. Please try again.');
          } 

      } else {
        alert('OTP Mismatch');
      }
    } else {
      setLoading(true);
      // Handle login logic here
      console.log('vcNumber:', vcNumber);
      const data = {
        username: vcNumber,
        password: password
      };

      try {
        const res = await authApi.fetchAgent(data);
        if (res.status === 200) {
          console.log(res.data);
          console.log(res.data.messageFlag);
          if(res.data.messageFlag == "S"){
          setAgentID(res.data.agentId);
          await getOTP(res.data.agentId);
            setVerified(true);
          }
          else{
                alert("Acount Not Found");
          }
        }
      } catch (error) {
        console.log('Failed to fetch agent', error);
        alert('An error occurred. Please try again.');
      } finally {
        setLoading(false); // Ensure loading is set to false after operation
      }
    }
  };

  async function getOTP(agent_id) {
    const data = {
      agent_id: agent_id,
      flag: 'A'
    };

    try {
      const res = await authApi.sendOtp(data);
      if (res.status === 200) {
        console.log(res.data);
        console.log(res.data.otp);
        setOtpCompare(res.data.otp);
      }
    } catch (error) {
      console.log('Failed to fetch OTP', error);
      alert('An error occurred. Please try again.');
    }
  }

  return (
    verified ?
      <YourComponent
        handleCheckboxChange={handleCheckboxChange}
        selectedOptions={selectedOptions}
        otpvalue={otpvalue}
        setOtpvalue={setOtpvalue}
        handleSubmit={handleSubmit}
      />
      :
      <div className="container">
        <div className="bottom-right">
          {loading ? (
            <Loader />
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="vcNumber"
                  placeholder="Login"
                  value={vcNumber}
                  onChange={(e) => setVcNumber(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">SUBMIT</button>
            </form>
          )}
        </div>
      </div>
  );
};

export default LoginPage;
